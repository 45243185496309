import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { generateIndexRouter } from '../util/common'
import { routes } from './config'
import {roleByUser} from "@/api/common";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

// 挂导航守卫
router.beforeEach((to, form, next) => {
  // 获取用户菜单
  if (store.getters.usermenu.length === 0 && to.path != '/login') {
    store.dispatch('getFunction').then((res) => {
      let constRoutes = []
      //报价员角色-报价单模块权限
      let hasBJ = 0
      //设计组角色-设计派工模块权限
      let hasSJ = 0
      roleByUser({user_id:store.getters.userinfo.id}).then(roles=>{
        if (roles?.data)
          roles.data.forEach(role => {
             if (role.role_id === "7fanu15exyua7l0eprsn"){
               hasBJ = 1
             }
             if (role.role_id === "01o1qoahgsua2x89kib3"){
               hasSJ = 1
             }
          })
          if (store.getters.userinfo.usertype === 0 && hasBJ===0){
              let index = res.data.findIndex(item=>item.id === "1373165096524308481")
              res.data.splice(index,1)
          }
        if (store.getters.userinfo.usertype === 0 && hasSJ===0){
          let index = res.data.findIndex(item=>item.id === "1373163479095828482")
          res.data.splice(index,1)
        }
        constRoutes = generateIndexRouter(res.data)
        store.dispatch('updateRoutes', constRoutes).then(() => {
          // 动态添加路由
          router.addRoutes(constRoutes)
        })
      })
    }).catch(err => {
      //登陆失败 重新登陆
      localStorage.removeItem("token")
      next({
        path: '/login',
        replace: true
      })
      location.reload()
    })
  }
  // 无令牌跳转到登录页，排除登录页
  const token = localStorage.getItem('token')
  if (!token && to.path !== '/login') {
    next({
      path: '/login',
      replace: true
    })
    location.reload()
  } else {
    next()
  }
})

export default router
