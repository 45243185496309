import request from '@/util/request'
import { getToken, paramsToFormData } from '../util/common'

// login
export function login (data) { return request({ url: '/base/login/token', method: 'post', data: data }) }
export function logout () { return request({ url: '/base/removeToken', method: 'post', data: { token: getToken() } }) }
export function userList (data) { return request({ url: '/base/queryUserList', method: 'get', params: data }) }
export function updateInfoByUse (data) { return request({ url: '/base/updateuserinfo', method: 'post', data: data }) }
export function updateImgByUse (data) { return request({ url: '/base/updateImgByUse', method: 'post', data: data }) }
export function updatePasswordByUse (data) { return request({ url: '/base/updatePasswordByUse', method: 'post', data: data }) }

// dept
export function depTree (data) { return request({ url: '/erp/queryDeptTree', method: 'get', params: data }) }
export function depList (data) { return request({ url: '/erp/queryDeptList', method: 'get', params: data }) }
export function CanSetdepList (data) { return request({ url: '/erp/queryCanSetList', method: 'get', params: data }) }
export function depDelete (data) { return request({ url: '/erp/deleteDept', method: 'post', data: data }) }
export function depAdd (data) { return request({ url: '/erp/addDept', method: 'post', data: data }) }
export function depEdit (data) { return request({ url: '/erp/editDept', method: 'post', data: data }) }

// role
export function roleList (data) { return request({ url: '/erp/queryRoleList', method: 'get', params: data }) }
export function roleAdd (data) { return request({ url: '/erp/addRole', method: 'post', data: data }) }
export function roleByUser (data) { return request({ url: '/erp/queryRoleByUser', method: 'get', params: data }) }
// user
export function userAdd (data) { return request({ url: '/base/addUser', method: 'post', data: data }) }
export function updateUser (data) { return request({ url: '/base/updateUser', method: 'post', data: data }) }
export function usersEditStatus (data) { return request({ url: '/setUserStatus', method: 'post', data: data }) }
export function getUserInfo (data) { return request({ url: '/base/getUserInfo', method: 'get', params: data }) }
export function getUserByRoleId (data) { return request({ url: '/base/queryUserByRoleId', method: 'get', params: data }) }
export function user_checkadvauth (data) { return request({ url: '/erp/user/checkadvauth', method: 'get', params: data }) }
export function changePassword (data) { return request({ url: '/base/changePassword', method: 'post', data: data }) }

export function editadvauth (data) { return request({ url: '/erp/user/editadvauth', method: 'post', data: data }) }
export function reSetAdvAuth (data) { return request({ url: '/erp/user/reSetAdvAuth', method: 'post', data: data }) }


// function
export function functionAdd (data) { return request({ url: '/erp/function/add', method: 'post', data: data }) }
export function functionEdit (data) { return request({ url: '/erp/function/edit', method: 'post', data: data }) }
export function functionDelete (data) { return request({ url: '/erp/function/delete', method: 'post', data: data }) }
export function functionTreeList (data) { return request({ url: '/erp/function/treelist', method: 'get', params: data }) }
export function userFunction (data) { return request({ url: '/erp/function/userfunction', method: 'get', params: data }) }

// itemlist
export function itemListAdd (data) { return request({ url: '/erp/itemlist/add', method: 'post', data: data }) }
export function itemListEdit (data) { return request({ url: '/erp/itemlist/edit', method: 'post', data: data }) }
export function itemListDelete (data) { return request({ url: '/erp/itemlist/delete', method: 'post', data: data }) }
export function itemListPlist (data) { return request({ url: '/erp/itemlist/plist', method: 'get', params: data }) }
export function itemListSlist (data) { return request({ url: '/erp/itemlist/slist', method: 'get', params: data }) }

// find
export function findAdd (data) { return request({ url: '/erp/find/add', method: 'post', data: data }) }
export function findEdit (data) { return request({ url: '/erp/find/edit', method: 'post', data: data }) }
export function findDelete (data) { return request({ url: '/erp/find/delete', method: 'post', data: data }) }
export function findList (data) { return request({ url: '/erp/find/list', method: 'get', params: data }) }
export function find_getbyfind (data) { return request({ url: '/erp/find/getbyfind', method: 'get', params: data }) }

// activity
export function activityList (data) { return request({ url: '/erp/activity/list', method: 'get', params: data }) }
export function activityAdd (data) { return request({ url: '/erp/activity/add', method: 'post', data: data }) }
export function activityEdit (data) { return request({ url: '/erp/activity/edit', method: 'post', data: data }) }
export function activityDelete (data) { return request({ url: '/erp/activity/delete', method: 'post', data: data }) }

// activitybody
export function activityBodyList (data) { return request({ url: '/erp/activitybody/list', method: 'get', params: data }) }
export function activityBodyAdd (data) { return request({ url: '/erp/activitybody/add', method: 'post', data: data }) }
export function activityBodyEdit (data) { return request({ url: '/erp/activitybody/edit', method: 'post', data: data }) }
export function activityBodyDelete (data) { return request({ url: '/erp/activitybody/delete', method: 'post', data: data }) }

// feibiaodingzhi
export function getallfeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/getall', method: 'get', params: data }) }
export function getonefeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/getone', method: 'get', params: data }) }
export function getuserallfeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/getuserall', method: 'get', params: data }) }
export function getlistfordispatch (data) { return request({ url: '/erp/feibiaodingzhi/getlistfordispatch', method: 'get', params: data }) }
export function addfeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/add', method: 'post', data: data }) }
export function updatefeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/update', method: 'post', data: data }) }
export function cancelfeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/cancel', method: 'post', params: data }) }
export function qutfeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/qut', method: 'post', data: data }) }
export function chkfeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/chk', method: 'post', params: data }) }
export function upgfeibiaodingzhi (data) { return request({ url: '/erp/feibiaodingzhi/upg', method: 'post', data: data }) }
export const feibiaodingzhi = {
  setdesigner (data) { return request({ url: '/erp/feibiaodingzhi/setdesigner', method: 'post', data: data }) },
  getuserlistfordesign (data) { return request({ url: '/erp/feibiaodingzhi/getuserlistfordesign', method: 'get', params: data }) },
  getlistforquoteprice (data) { return request({ url: '/erp/feibiaodingzhi/getlistforquoteprice', method: 'get', params: data }) },
  incheck (data) { return request({ url: '/erp/feibiaodingzhi/incheck', method: 'post', params: data }) },
  outcheck (data) { return request({ url: '/erp/feibiaodingzhi/outcheck', method: 'post', params: data }) },
  outcheck2 (data) { return request({ url: '/erp/feibiaodingzhi/outcheck2', method: 'post', params: data }) },
  getworka (data) { return request({ url: '/erp/feibiaodingzhi/getworka', method: 'post', data: data }) },
  getworkb (data) { return request({ url: '/erp/feibiaodingzhi/getworkb', method: 'post', data: data }) },
  getbaojiabill (data) { return request({ url: '/erp/feibiaodingzhi/getbaojiabill', method: 'get', params: data }) },
  fcancel (data) { return request({ url: '/erp/feibiaodingzhi/fcancel', method: 'post', data: data }) },
  exportexcel (data) { return down('/erp/feibiaodingzhi/exportExcel', data) },
  updateProImgs (data) { return request({ url: '/erp/feibiaodingzhi/updateProImgs', method: 'post', data: data }) },
  jump (data) { return request({ url: '/erp/feibiaodingzhi/jump', method: 'post', data: data }) }
}
export const postsaleshead = {
  savebill (data) { return request({ url: '/erp/postsaleshead/savebill', method: 'post', data: data }) },
  getlist (data) { return request({ url: '/erp/postsaleshead/getlist', method: 'get', params: data }) },
  getbill (data) { return request({ url: '/erp/postsaleshead/getbill', method: 'get', params: data }) },
  // submit (data) { return request({ url: '/erp/postsaleshead/submit', method: 'post', data: data }) },
  getUserAfterSaleList (data) { return request({ url: '/erp/postsaleshead/getUserAfterSaleList', method: 'get', params: data }) },
  export (data) { return down('/erp/postsaleshead/exportExcel', data) },
  cancel(data){ return request({ url: '/erp/postsaleshead/cancel', method: 'post', params: data })}
}



export const a1commain = {
  savebilla (data) { return request({ url: '/erp/a1commain/savebilla', method: 'post', data: data }) },
  getbilla(data) { return request({ url: '/erp/a1commain/getbilla', method: 'get', params: data }) },
  cancela(data) { return request({ url: '/erp/a1commain/cancela', method: 'post', data: data }) },
  getlista(data) { return request({ url: '/erp/a1commain/getlista', method: 'get', params: data }) },
}

export const afterprocessing = {
  save (data) { return request({ url: '/erp/afterprocessing/save', method: 'post', data: data }) },
  getInstance (data) { return request({ url: '/erp/afterprocessing/getInstance', method: 'get', params: data }) }
}

// buslog
export function getbuslog (data) { return request({ url: '/erp/buslog/getlog', method: 'get', params: data }) }

// stock
export function getallstock (data) { return request({ url: '/erp/stock/getall', method: 'get', params: data }) }
export function getallmyorder (data) { return request({ url: '/erp/stock/getmyorder', method: 'get', params: data }) }
export function getgoodprice (data) { return request({ url: '/erp/stock/getgoodprice', method: 'get', params: data }) }
export function geterpuser (data) { return request({ url: '/erp/stock/geterpuser', method: 'get', params: data }) }
export function seterpcusdefpass (data) { return request({ url: '/erp/stock/seterpcusdefpass', method: 'post', params: data }) }
export function getaccount (data) { return request({ url: '/erp/stock/getaccount', method: 'get', params: data }) }
export function getdeliverygoods (data) { return request({ url: '/erp/stock/getdeliverygoods', method: 'get', params: data }) }
export function getWuLiuXinXi (data) { return request({ url: '/erp/stock/getWuLiuXinXi', method: 'get', params: data }) }



export const gycommain = {
  savebill (data) { return request({ url: '/erp/gycommain/savebill', method: 'post', data: data }) },
  savebillv2 (data) { return request({ url: '/erp/gycommain/savebillv2', method: 'post', data: data }) },
  getbill (data) { return request({ url: '/erp/gycommain/getbill', method: 'post', params: data }) },
  check (data) { return request({ url: '/erp/gycommain/check', method: 'post', data: data }) },
  uncheck (data) { return request({ url: '/erp/gycommain/uncheck', method: 'post', data: data }) },
  getdetail(data) { return request({ url: '/erp/gycommain/getdetail', method: 'get', params: data }) }
}
export const designflow = {
  getbilldetail (data) { return request({ url: '/erp/designflow/getbilldetail', method: 'get', params: data }) },
  add (data) { return request({ url: '/erp/designflow/add', method: 'post', data: data }) },
  postimg (data) { return request({ url: '/erp/designflow/postimg', method: 'post', data: data }) },
  postimg2 (data) { return request({ url: '/erp/designflow/postimg2', method: 'post', data: data }) },
  post (data) { return request({ url: '/erp/designflow/post', method: 'post', data: data }) },
  incheck (data) { return request({ url: '/erp/designflow/incheck', method: 'post', data: data }) },
  outcheck (data) { return request({ url: '/erp/designflow/outcheck', method: 'post', data: data }) },
  inuncheck (data) { return request({ url: '/erp/designflow/inuncheck', method: 'post', data: data }) },
  outuncheck (data) { return request({ url: '/erp/designflow/outuncheck', method: 'post', data: data }) },
  getPassIns (data) { return request({ url: '/erp/designflow/getPassIns', method: 'get', params: data }) }
}

export const noteMsg = {
  saveData(data) { return request({ url: '/erp/notemsg/saveData', method: 'post', data: data }) },
  release(data) { return request({ url: '/erp/notemsg/release', method: 'post', data: data }) },
  list (data) { return request({ url: '/erp/notemsg/list', method: 'get', params: data }) },
  msg (data) { return request({ url: '/erp/notemsg/msg', method: 'get', params: data }) },
  getTopic(data){return request({ url: '/erp/notemsg/getTopic', method: 'get', params: data })},
  getUnreadTopicByUser(data){return request({ url: '/erp/notemsg/getUnreadTopicByUser', method: 'get', params: data })},
  setReadFlag(data){ return request({ url: '/erp/notemsg/setReadFlag', method: 'post', data: data }) }
}

export const noteMsgUser ={
  getlist(data){return request({ url: '/erp/notemsguser/getlist', method: 'get', params: data })}
}

export const process = {
  getModelXml (data) { return request({ url: '/base/process/getModelXml', method: 'get', params: data }) },
  saveModel (data) { return request({ url: '/base/process/SaveModel', method: 'post', data: data }) },
  deployment (data) { return request({ url: '/base/process/deployment', method: 'post', data: data }) },
  getBpmOperate (data) { return request({ url: '/base/process/getBpmOperate', method: 'get', params: data }) },
  start (data) { return request({ url: '/base/process/start', method: 'post', data: data }) },
  completTask (data) { return request({ url: '/base/process/completTask', method: 'post', data: data }) },
  startAndComplet (data) { return request({ url: '/base/process/startAndComplet', method: 'post', data: data }) },
  rejectPreTask (data) { return request({ url: '/base/process/rejectPreTask', method: 'post', data: data }) },
  getClaimsAndTodo (data) { return request({ url: '/base/process/getClaimsAndTodo', method: 'get', params: data }) },
  getPageClaimsAndTodo (data) { return request({ url: '/base/process/getPageClaimsAndTodo', method: 'get', params: data }) },
  cancel(data) { return request({ url: '/base/process/cancel', method: 'post', data: data }) },
  getModelList(data) { return request({ url: '/base/process/getModelList', method: 'get', params: data }) },
  deleteModel(data) { return request({ url: '/base/process/deleteModel', method: 'post', data: data }) },
}



/**
 * 文件上传
 */
export function upload (url, data) {
  return request({
    method: 'post',
    url: url,
    headers: { 'Content-Type': 'multipart/form-data', Authorization: 'Bearer ' + getToken() },
    data: paramsToFormData(data)
  })
}
export function down (url, data) {
  return request({
    method: 'get',
    url: url,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', Authorization: 'Bearer ' + getToken() },
    responseType: 'blob',
    params: data,
    timeout: 300000
  })
}
